








import { Component, Vue } from "vue-property-decorator";
import Modalfooter from "@/components/common/modalfooter.vue";

@Component({
  components: { Modalfooter }
})
export default class PaymentConfirmView extends Vue {
  notification: any = {
    message: "Вывод денежных средств подтвержден.",
    type: 2
  };
}
